export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [0,16,2,8,9,4,10,11,18,12];

export const dictionary = {
		"/(landingpage)": [~44,[16]],
		"/(landingpage)/(pages)/Tutorial": [45,[16]],
		"/(landingpage)/(pages)/aviso_privacidad": [46,[16]],
		"/(landingpage)/(pages)/blog/[post]/[id]": [~47,[16]],
		"/(landingpage)/(pages)/brokers": [48,[16]],
		"/(landingpage)/(pages)/catalogo_de_contratos": [49,[16,17]],
		"/(landingpage)/(pages)/catalogo_de_contratos/prueba_concepto_templates_dinamicos": [51,[16,17]],
		"/(landingpage)/(pages)/catalogo_de_contratos/[templateId]": [50,[16,17]],
		"/(app)/documentos": [~19,[2,3]],
		"/(app)/documentos/documento/crear": [~29,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo": [~30,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo/[templateId]": [~31,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_subiendo_pdf": [~32,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_vacio": [~33,[2,3]],
		"/(app)/documentos/documento/[documentId]/v1/esperando_firmas": [~22,[5,6]],
		"/(app)/documentos/documento/[documentId]/v1/firmando_pdf": [~23,[5,7]],
		"/(app)/documentos/documento/[documentId]/v1/invitando_firmar": [24,[8]],
		"/(app)/documentos/documento/[documentId]/v1/recolectando_firmantes": [~25,[5]],
		"/(app)/documentos/documento/[documentId]/v1/sellos_pdf": [~26,[5]],
		"/(app)/documentos/documento/[documentId]/v1/todos_firmaron": [~27,[5]],
		"/(app)/documentos/documento/[documentId]/v1/view": [~28,[9]],
		"/(app)/documentos/documento/[documentId]/[deprecatedUrls]": [~21,[2,3,4]],
		"/(app)/documentos/template/[documentId]/edit": [34,[10]],
		"/(app)/documentos/[carpeta]": [20,[2,3]],
		"/(landingpage)/(pages)/features/firma_autografa": [52,[16]],
		"/(landingpage)/(pages)/features/firma_avanzada": [53,[16]],
		"/(landingpage)/(pages)/features/firma_digital_documentos": [54,[16]],
		"/(landingpage)/(pages)/features/gestion_documentos": [55,[16]],
		"/(landingpage)/(pages)/features/live_check": [56,[16]],
		"/(landingpage)/(pages)/features/nom_151": [57,[16]],
		"/(landingpage)/(pages)/features/registro_marca": [58,[16]],
		"/(landingpage)/(pages)/features/registros_auditoria": [59,[16]],
		"/(landingpage)/(pages)/features/sellos_tiempo_digital": [60,[16]],
		"/(landingpage)/(pages)/industrias/legal_notarias": [~61,[16]],
		"/(app)/onboarding": [35,[11]],
		"/(landingpage)/(pages)/precios": [62,[16,18]],
		"/(landingpage)/(pages)/precios/checkout": [63,[16,18]],
		"/(landingpage)/(pages)/precios/checkout/gracias/[membresia]": [64,[16,18]],
		"/(landingpage)/(pages)/prelanzamiento": [65,[16]],
		"/(landingpage)/(pages)/programa_referidos_para_ti": [67,[16]],
		"/(landingpage)/(pages)/programa_referidos": [66,[16]],
		"/(app)/referidos/admin": [36,[2,12]],
		"/(app)/referidos/felicidades": [37,[2,12]],
		"/(landingpage)/(pages)/registrate": [68,[16]],
		"/(landingpage)/(pages)/seguridad": [69,[16]],
		"/(app)/settings/facturacion": [38,[2,13,14]],
		"/(app)/settings/membresia": [39,[2,13,15]],
		"/(app)/settings/membresia/documentos_terminados": [40,[2,13,15]],
		"/(app)/settings/membresia/gracias/[membresia]": [41,[2]],
		"/(app)/settings/membresia/membresia_vencida": [42,[2,13,15]],
		"/(app)/settings/mi_cuenta": [43,[2,13]],
		"/(landingpage)/(pages)/signin": [70,[16]],
		"/(landingpage)/(pages)/signin/adminauth": [71,[16]],
		"/(landingpage)/(pages)/signin/correo_enviado": [72,[16]],
		"/(landingpage)/(pages)/signin/error": [~73,[16]],
		"/(landingpage)/(pages)/terminos_condiciones": [74,[16]],
		"/(landingpage)/(pages)/validador_documentos_pdf_firmas_digitales_allsign": [75,[16]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';